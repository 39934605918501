import React, { useEffect, useState } from 'react';
import Layout from '../../component/Admin/AdminLayout';
import AdminHeader from './component/AdminHeader';
import Swal from 'sweetalert2'
import { Link, useNavigate } from 'react-router-dom';
import { message } from 'antd';
import Pagination from './component/Pagination';
// import SearchBookingList from './component/SearchBookingList';
import api from "../api";
import {BASE_URL} from "../constant";

const BookingList = () => {
    const navigate = useNavigate()
    const [allBookings, setAllBookings] = useState(null);
    const [totalData, setTotalData] = useState(null)
    const [status, setStatus] = useState(null);
    const Month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

    // Pagination part
    const [currentPage, setCurrentPage] = useState(1);
    const [bookingPerPage] = useState(10);

    const indexOfLastBooking = currentPage * bookingPerPage;
    const indexOfFirstBooking = indexOfLastBooking - bookingPerPage;
    const currentBookingList = allBookings?.slice(indexOfFirstBooking, indexOfLastBooking);
    const totalInquiry = allBookings?.length;

    // Format currency
    const formatCurrency = (amount) => {
        if (!amount) return '₹0';
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 0
        }).format(amount);
    };

    // To upper case function
    const toUpperCase = (string) => {
        return string?.charAt(0).toUpperCase() + string?.slice(1);
    }

    // Get payment status style
    const getPaymentStatusStyle = (status) => {
        switch(status?.toLowerCase()) {
            case 'completed':
            case 'success':
                return 'bg-success text-white';
            case 'pending':
                return 'bg-warning text-dark';
            case 'failed':
                return 'bg-danger text-white';
            default:
                return 'bg-secondary text-white';
        }
    };

    // Get all bookings
    const getAllBookingDetails = (status) => {
        api(BASE_URL)
            .get(`book_of_trips/booking/get-all-booking`)
            .then((res) => {
                let arr = res.data.data
                let newArr = arr.reverse()

                let newdate = new Date().getDate();
                let newmonth = new Date().getMonth();
                let newyear = new Date().getFullYear();
                let currentDate = newyear+'-'+Month[newmonth]+'-'+newdate
                let newCurrentdate = new Date(currentDate).toISOString().slice(0, 10);

                const getCurrentBooking = (status) => {
                    let AllBookings = newArr?.filter((booking) => {
                        let date = new Date(booking.details.split(' ')[0])
                        let newDate = date.getDate()
                        let newMonth = date.getMonth()
                        let newYear = date.getFullYear()
                        let bookingDate = newYear+'-'+Month[newMonth]+'-'+newDate
                        let newBookingDate = new Date(bookingDate).toISOString().slice(0, 10);

                        let data
                        if(status === null || status === 'current'){
                            data = newBookingDate >= newCurrentdate
                        }
                        if(status === 'expired'){
                            data = newBookingDate < newCurrentdate
                        }
                        return data
                    })
                    setAllBookings(AllBookings);
                    setTotalData(AllBookings?.length)
                }

                if(status === 'expired'){
                    getCurrentBooking(status)
                } else if(status === 'current'){
                    getCurrentBooking(status)
                } else if(status === null) {
                    getCurrentBooking(status)
                }
            }).catch((err) => {
                console.log(err);
                message.error('Error fetching bookings');
            });
    }

    // Handle booking status change
    const onHandleBooking = (value) => {
        setStatus(value)
    }

    // Delete booking
    const deleteBooking = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                api(BASE_URL).post(`/book_of_trips/booking/delete-booking`,
                    { id: id },
                ).then((res) => {
                    getAllBookingDetails(status)
                    message.success(res.data.message)
                }).catch((err) => {
                    console.log(err);
                    message.error('Error deleting booking');
                });
            }
        });
    }
    
    useEffect(() => {
        getAllBookingDetails(status)
    }, [status])

    const cardStyle = {
        bookingCard: {
            cursor: 'pointer',
            transition: 'all 0.2s ease',
            backgroundColor: '#fff',
            border: '1px solid rgba(0,0,0,0.1)',
            borderRadius: '8px',
        },
        paymentInfo: {
            backgroundColor: 'rgba(0,0,0,0.02)',
            padding: '12px',
            borderRadius: '6px',
            marginTop: '10px'
        },
        badge: {
            padding: '4px 8px',
            fontSize: '0.85rem',
            borderRadius: '4px'
        }
    };

    const getPaymentStatusStyle2 = (status) => {
        if (!status) return 'bg-secondary text-white';
        
        switch(status.toLowerCase()) {
            case 'completed':
            case 'success':
                return 'bg-success text-white';
            case 'pending':
                return 'bg-warning text-dark';
            case 'failed':
                return 'bg-danger text-white';
            default:
                return 'bg-secondary text-white';
        }
    };
    
    return (
        <Layout>
            <AdminHeader title='Trip Booking List' redirect='/admin-dashboard'/>
            <div className="container px-4 pb-4">
                <div className='py-3 d-flex justify-content-between align-items-center'>
                    {/* <div className="w-100 w-md-auto">
                        <SearchBookingList setProduct={setAllBookings}/>
                    </div> */}
                    <div className='d-flex'>
                        <div className="btn-group d-flex justify-content-center align-items-center">
                        <p className='m-0 px-3 py-2 bg-warning rounded me-2 text-white'>Total Booking:</p>

                            <p className='bg-info m-0 px-3 py-2 rounded me-2'>{totalData || '0'}</p>
                        </div>
                        <div className="btn-group bg-warning border-0">
                            {/* <button className="btn btn-secondary dropdown-toggle bg-transparent text-black px-4 py-2 border-0" 
                                    type="button" 
                                    data-bs-toggle="dropdown" 
                                    aria-expanded="false">
                                {status? toUpperCase(status) : 'All'} Bookings
                            </button> */}
                            <ul className="dropdown-menu">
                                <li><Link className="dropdown-item" to="#" onClick={() => onHandleBooking('current')}>Current Booking</Link></li>
                                <li><Link className="dropdown-item" to="#" onClick={() => onHandleBooking('expired')}>Expired Booking</Link></li>
                                {/* <li><Link className="dropdown-item" to="#" onClick={() => onHandleBooking(null)}>All Bookings</Link></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
                <hr />
                {allBookings?.length === 0 ? (
                    <div className='d-flex flex-column justify-content-center align-items-center mt-5'>
                        <h3>No Record Found</h3>
                    </div>
                ) : (
                    <div>
                        {currentBookingList?.map((booking, index) => {
                            const date = new Date(booking.created_at);
                            const formattedDate = date.toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric'
                            });

                            return (
                                <div key={booking.id} className='position-relative'>
                                    <div style={cardStyle.bookingCard}
                                         className='my-4 p-3'
                                         onClick={() => navigate(`/booking-trip/${booking.id}`)}
                                         onMouseEnter={(e) => e.currentTarget.style.transform = 'translateY(-2px)'}
                                         onMouseLeave={(e) => e.currentTarget.style.transform = 'none'}>
                                        <div className='d-flex justify-content-between align-items-start'>
                                            <div style={{flex: 1}}>
                                                <div className='d-flex align-items-center gap-2 mb-3'>
                                                    <h5 className='m-0'>
                                                        <strong>BookingId: </strong>
                                                        <mark className='p-0 m-0 px-2'>{booking.sequence}</mark>
                                                    </h5>
                                                    {booking.payment?.status && (
                                                        <span className={`badge ${getPaymentStatusStyle2(booking.payment.status)}`}
                                                              style={cardStyle.badge}>
                                                            {toUpperCase(booking.payment.status)}
                                                        </span>
                                                    )}
                                                </div>

                                                <h5 className='mb-2'>
                                                    <strong>{booking.title}</strong>
                                                    <span className='text-danger ms-3'>{booking.tripId?.location}</span>
                                                </h5>
                                                
                                                <p className='mb-2'>
                                                    <strong>Trip Date: </strong>
                                                    {booking.details?.split(' ')[0]}
                                                </p>

                                                <hr />

                                                <div>
                                                    <h5 className='m-0 mb-2'>
                                                        <strong>{toUpperCase(booking.username)}</strong>
                                                    </h5>
                                                    <p className='m-0'>from {toUpperCase(booking.address)}</p>
                                                    <p className='m-0'><strong>Phone:</strong> {booking.phone}</p>
                                                    <p className='m-0'><strong>Email:</strong> {booking.email}</p>
                                                    <p className='m-0'><strong>Booking Date:</strong> {formattedDate}</p>
                                                </div>

                                                {booking.payment && (
                                                    <div style={cardStyle.paymentInfo}>
                                                        <h6 className='mb-2'><strong>Payment Details</strong></h6>
                                                        <div className='d-flex gap-3'>
                                                            <p className='m-0'>
                                                                <strong>Adv Status:</strong>{' '}
                                                                <span className={`badge ${getPaymentStatusStyle(booking.payment.status)}`}>
                                                                    {booking.payment.status ? toUpperCase(booking.payment.status) : 'No'}
                                                                </span>
                                                            </p>
                                                            <p className='m-0'><strong>Total:</strong> {formatCurrency(booking.payment.total)}</p>
                                                            <p className='m-0'><strong>Advance:</strong> {formatCurrency(booking.payment.advance)}</p>
                                                            <p className='m-0'><strong>Remaining:</strong> {formatCurrency(booking.payment.remaining)}</p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            {/* <div className='ms-3'>
                                                <button className='btn btn-sm btn-danger'
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            deleteBooking(booking.id);
                                                        }}>
                                                    Delete
                                                </button>
                                            </div> */}
                                        </div>
                                    </div>
                                    <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-primary">
                                        {index + 1 + indexOfFirstBooking}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                )}
                {allBookings?.length > 0 && (
                    <Pagination 
                        totalInquiry={totalInquiry}
                        setCurrentPage={setCurrentPage}
                        inquiryPerPage={bookingPerPage}
                        currentPage={currentPage}
                    />
                )}
            </div>
        </Layout>
    );
}

export default BookingList;