import React, { useEffect, useState } from 'react'
import Layout from '../../component/Admin/AdminLayout'
import AdminHeader from './component/AdminHeader'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {BASE_URL} from "../constant";
import api from "../api";

const BookingTrip = () => {
    const [bookingTrip, setBookingTrip] = useState(null)
    const { id } = useParams();

    const toUpperCase = (string) =>{
        return string?.charAt(0).toUpperCase() + string?.slice(1);
    }

    const getBookingTrip = (id)=>{
        
         api(BASE_URL).post('/book_of_trips/booking/get-booking', {id: id})
            .then((res) => {
            setBookingTrip(res.data.data);
        }).catch((err) => {
            console.log(err);
        });
    }
    useEffect(()=>{
        if(id){
            getBookingTrip(id)
        } 
        // eslint-disable-next-line
    }, [])
  return (
    <Layout>
        <AdminHeader title={`Trip Booking List / ${bookingTrip?.username}`} redirect='/booking-list'/>
        <div className='Mon_font'>
            <div className='px-4'>
                <h4 className='m-0'><i className="fa-solid fa-user text-warning txt-xlg px-4"></i>User details</h4>
                <div className='card my-3 p-3'>
                    <div className='d-flex justify-content-start align-items-center py-2'>
                        <h6 className='m-0 pe-4'><strong>Name:</strong></h6><h6 className='m-0'>{toUpperCase(bookingTrip?.username)}</h6>
                    </div>
                    <div className='d-flex justify-content-start align-items-center py-2'>
                        <h6 className='m-0 pe-4'><strong>Phone:</strong></h6><h6 className='m-0'>{toUpperCase(bookingTrip?.phone)}</h6>
                    </div>
                    <div className='d-flex justify-content-start align-items-center py-2'>
                        <h6 className='m-0 pe-4'><strong>Email:</strong></h6><h6 className='m-0'>{toUpperCase(bookingTrip?.email)}</h6>
                    </div>
                    <div className='d-flex justify-content-start align-items-center py-2'>
                        <h6 className='m-0 pe-4'><strong>Address:</strong></h6><h6 className='m-0'>{toUpperCase(bookingTrip?.address)}</h6>
                    </div>
                </div>
            </div>
            <div className='px-4'>
                <h4 className='m-0'><i className="fa-solid fa-user text-warning txt-xlg px-4 mt-3"></i>Trip details</h4>
                <div id='all-booking-list' className='card my-3 p-3 ' 
                    onClick={()=>{
                        window.open(`/${(bookingTrip?.tripId?.trip_url.toLowerCase()).replace(/\s+/g, '-')}`, '_blank');
                    }}
                >
                    <div className='d-flex justify-content-start align-items-center py-2'>
                        <h6 className='m-0 pe-4'><strong>Title:</strong></h6><h6 className='m-0'>{toUpperCase(bookingTrip?.tripId.heading)}</h6>
                    </div>
                    <div className='d-flex justify-content-start align-items-center py-2'>
                        <h6 className='m-0 pe-4'><strong>Location:</strong></h6><h6 className='m-0'>{toUpperCase(bookingTrip?.tripId.location)}</h6>
                    </div>
                </div>
            </div>
            <div className='px-4'>
                <h4 className='m-0'><i className="fa-solid fa-user text-warning txt-xlg px-4 mt-3"></i>Booking details</h4>
                <div className='card my-3 p-3 '>
                    <div className='d-flex justify-content-start align-items-center py-2'>
                        <h6 className='m-0 pe-4'><strong>TxnID:</strong></h6><h6 className='m-0'>{bookingTrip?.txnid}</h6>
                    </div>
                    <div className='d-flex justify-content-start align-items-center py-2'>
                        <h6 className='m-0 pe-4'><strong>Adv Payemnt Status:</strong></h6><h6 className='m-0'>{bookingTrip?.payment_status}</h6>
                    </div>
                    <div className='d-flex justify-content-start align-items-center py-2'>
                        <h6 className='m-0 pe-4'><strong>Date:</strong></h6><h6 className='m-0'>{toUpperCase(bookingTrip?.details?.split(' ')[0])}</h6>
                    </div>
                    <div className='d-flex justify-content-start align-items-center py-2'>
                        <h6 className='m-0 pe-4'><strong>Type:</strong></h6><h6 className='m-0'>{toUpperCase(bookingTrip?.details)}</h6>
                    </div>
                    <div className='d-flex justify-content-start align-items-center py-2'>
                        <h6 className='m-0 pe-4'><strong>Total:</strong></h6><h6 className='m-0'>{bookingTrip?.total}</h6>
                    </div>
                    <div className='d-flex justify-content-start align-items-center py-2'>
                        <h6 className='m-0 pe-4'><strong>Advance:</strong></h6><h6 className='m-0'>{bookingTrip?.advance}</h6>
                    </div>
                    <div className='d-flex justify-content-start align-items-center py-2'>
                        <h6 className='m-0 pe-4'><strong>Remaining:</strong></h6><h6 className='m-0'>{bookingTrip?.remaining}</h6>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
  )
}

export default BookingTrip
