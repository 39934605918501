import React, { useEffect, useState } from 'react';
import Layout from '../../component/Admin/AdminLayout';
import AdminHeader from './component/AdminHeader';
import { message } from 'antd';
import axios from 'axios';
import {BASE_URL} from "../constant";
import api from "../api";
import {getAllCategoryData} from "../../util/GetAllCategory";

const Banner = () => {
    const [loading, setLoading] = useState(false);
    const [inputFields, setInputFields] = useState(null);
    const [data, setData] = useState(null);
    const [category, setAllCategory] = useState(null);


    const getAllCategory = async () => {
        await api(BASE_URL)
            .get(`book_of_trips/category/get-all-category`).then((res) => {
            let categories = []
            if(res.data.data){
                res.data?.data.forEach(category => {
                    categories.push(category.name);
                });
            }
            setAllCategory(categories);
            
            }).catch((err) => {
                console.log(err);
            });
    }

    useEffect(()=>{
        getAllCategory()
        getAllBanner()
        // eslint-disable-next-line
    }, [])


    // banner image cloudinary API
    const postDetails = (image) => {
        if (!image) {
            return message.error('Please Select an image');
        }
        if (image.type === 'image/jpeg' || image.type === 'image/png') {
            const data = new FormData();
            data.append('file', image);
            setLoading(true)
            const headers = {};
            headers["authorization"] = `${window.localStorage.getItem("auth_key")}`

            fetch(`${BASE_URL}/book_of_trips/admin/upload`, {
                method: 'post',
                body: data,
                headers: headers,
            }).then((res) => res.json()).then((data) => {
                setLoading(false)
                setInputFields({ ...inputFields, image: data.secure_url })
            }).catch((err) => {
                message.error(err);
            })
        } else {
            return message.error('Please Select an image');
        }
    };

    // on form value change
    const handleChange = (event, value) => {
        if (value === 'bannerImage') {
            postDetails(event.target.files[0])
        }
        setInputFields({ ...inputFields, [event.target.name]: event.target.value })
    }

    //on form submit
    const submitHandler = (e) => {
        e.preventDefault();
        setLoading(true)
            api(BASE_URL).post(`book_of_trips/banners/add-banner`,{
                location: inputFields.location,
                image: inputFields.image,
            }).then((res) => {
            getAllBanner()
            setTimeout(() => {
                setLoading(false)
            }, 1500);
            message.success(res.data.message)
        }).catch((err) => {
            setTimeout(() => {
                setLoading(false)
            }, 1500);
            console.log(err);
        });
    }

    // on banner delete
    const deleteBanner = (id) => {
            api(BASE_URL).post(`book_of_trips/banners/delete-banner`, {
                id: id,
            })
            .then((res) => {
            getAllBanner()
            message.success(res.data.message)
        }).catch((err) => {
            console.log(err);
        });
    }

    const getAllBanner = () => {
         api(BASE_URL)
            .get(`book_of_trips/banners/get-all-banner`)
            .then((res) => {
            setData(res.data.data)
        }).catch((err) => {
            console.log(err);
        });
    }
    return (
        <Layout>
            <AdminHeader title='Promotional Banner' redirect='/admin-dashboard' btnName='Add City Image' btnUrl='/add-city-image' btnName2='' btnUrl2='/add-coupon-code'/>
            <div className="container Mon_font px-4">
                <form className="" style={{ backgroundColor: "#fff" }} onSubmit={submitHandler}>
                    <div className="col-3 my-3">
                        <label htmlFor="location" className="form-label">Select Location</label>
                        <select className="form-select" value={inputFields?.location} required name="location" onChange={(event) => { handleChange(event) }}>
                            <option value="">Select</option>
                            {category && category.map((category) => {
                                return (
                                    <option value={category}>{category}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-3 my-3">
                        <label htmlFor="tripImage" className="form-label">Add Banner</label>
                        <input required type="file" accept="image/*" className="form-control" id="tripImage" onChange={(event) => { handleChange(event, 'bannerImage') }} />
                        <span className='text-secondary'><small>Banner size - 1350x375px</small></span>
                        {inputFields?.image && <div className="col-4 my-2">
                            <img width={340} src={inputFields.image} alt="Loading" />
                        </div>}
                    </div>
                    <hr />
                    <div className='my-2'>
                        <button disabled={loading === true} type='submit' className='btn btn-warning w-100 '>{loading ? 'Loading...' : 'Submit'}</button>
                    </div>
                </form>
            </div>

            {/* all banners */}
            <div className="container Mon_font px-4 pb-4">
                <table className='table table-light table-bordered w-100 m-0 mt-4'>
                    <thead className='p-2 py-3 table-secondary'>
                        <tr>
                            <th scope="col" className='py-2'>Banner</th>
                            <th scope="col" className='py-2'>Location</th>
                            <th scope="col" className='py-2'>Action</th>
                            <th scope="col" className='py-2'>Active</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((data) => {
                            return <tr className='m-2' key={data.id}>
                                <td className=''>
                                    <img className='m-2' width={300} src={data.image} alt="Loading" />
                                </td>
                                <td className='text-danger'>{data.location}</td>
                                <td className='ps-4'>
                                    <div className="flex gap-2">
                                        <i className="fa-sharp fa-solid fa-trash cursor-pointer p-2" onClick={() => deleteBanner(data.id)}></i>
                                    </div>
                                </td>
                                <td className='m-2'>{data.deleted.toString()}</td>
                            </tr>
                        })
                        }
                    </tbody>
                </table>
            </div>
        </Layout>
    )
}

export default Banner
